import React, { Fragment, PureComponent } from 'react'
import { Layout, Jumbotron, Sides, Marks } from '../../../components'
import '../../style.less'

class StartersGrill extends PureComponent {
  componentDidMount () {
    document.body.scrollTop = 0
  }

  render () {
    return (
      <Fragment>
        <Layout
          title={'Starters & Grill'}
          location={this.props.location}
        >
          <Jumbotron
            heading={'Starters and Grill'}
            image={'ribs.jpg'}
          />
          <div className='container'>
            <Marks />
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <h2 className='para-h2'>Starters</h2>
                <div>
                  <div className='para-h4'>
                    <h4>Bread &amp; Olives <span className='vegetarian-mark'>V</span><p className='pull-right'>£3.95</p></h4>
                    <p>Served With Balsamic Olive Oil <i>(Gluten Free roll available upon request)</i></p>
                  </div>
                  <div className='para-h4'>
                    <h4>Halloumi Fries <span className='vegetarian-mark'>V</span><p className='pull-right'>£7.25</p></h4>
                    <p>Lightly Coated &amp; Fried Served With A Trio Of Sauce.</p>
                  </div>
                  <div className='para-h4'>
                    <h4>Homemade Soup <span className='vegetarian-mark'>V</span><p className='pull-right'>£6.25</p></h4>
                    <p>All of our Soups are freshly prepared, &amp; are Gluten, Dairy &amp; Meat free. Served with Freshly Baked Baguette &amp; Topped With Crispy Onions <i>(Gluten Free roll available upon request)</i></p>
                  </div>
                  <div className='para-h4'>
                    <h4>Bruschetta <span className='vg-mark'>VG</span> <p className='pull-right'>£6.25</p></h4>
                    <p>Ciabatta Topped With Tomatoes, Black Olives, Garlic &amp; Coriander Finished With Balsamic Glaze.</p>
                  </div>
                  <div className='para-h4'>
                    <h4>Nachos <span className='gf-mark'>GF</span></h4>
                    <p>Nachos, Topped With Salsa And Melted Cheese, Served With Sour Cream &amp; Guacamole Dip</p>
                    <div className='row'>
                      <div className='col-md-12 col-xs-12'>
                        <h4 className='mt-0'>Single<p className='pull-right'>£5.75</p></h4>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12 col-xs-12'>
                        <h4 className='mt-0'>Sharer<p className='pull-right'>£10.95</p></h4>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12 col-xs-12'>
                        <p className='mt-0'>Add Homemade Chilli Or Homemade BBQ Pulled Pork<p className='pull-right'>£2.95</p></p>
                      </div>
                    </div>
                  </div>
                  <div className='para-h4'>
                    <h4>Prawn Cocktail<p className='pull-right'>£7.65</p></h4>
                    <p>Served On A Bed Of Mixed Leaves, Topped With Marie Rose Sauce With Freshly Baked Baguette. <i>(Gluten Free roll available upon request)</i></p>
                  </div>
                  <div className='para-h4'>
                    <h4>Chicken Wings <span className='gf-mark'>GF</span></h4>
                    <p>Lightly Spiced &amp; Served With A Trio Of Sauces</p>
                    <div className='row'>
                      <div className='col-md-12 col-xs-12'>
                        <h4 className='mt-0'>Single<p className='pull-right'>£5.75</p></h4>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12 col-xs-12'>
                        <h4 className='mt-0'>Sharer<p className='pull-right'>£10.95</p></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12 para-h4'>
                <div className='para-h2'>
                  <h2>Black Rock Grill - Dinning that Simply Rocks</h2>
                </div>
                <div>
                  <b>Enjoy a truly unique dinning experience at your table. Cook your Steak just the way you like it on hot granite rocks. All Steaks Are Served With Chips, Sautéed Mushrooms, Grilled Cherry Vine Tomatoes, Garden Peas &amp; Beer Battered Onion Rings.</b> <i>Unable to be served outside</i>
                  <div className='row'>
                    <div className='col-md-10 col-xs-9'>
                      <h4 className='mt-0'>8oz - Prime Cut Fillet Steak</h4>
                    </div>
                    <div className='col-md-2 col-xs-3'>
                      <div className='pull-right' style={{ fontSize: '18px' }}>£27.95</div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-10 col-xs-9'>
                      <h4 className='mt-0'>12oz - Prime Cut Rib Eye Steak</h4>
                    </div>
                    <div className='col-md-2 col-xs-3'>
                      <div className='pull-right' style={{ fontSize: '18px' }}>£25.95</div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <h4 className='mt-0'>Steak Sides</h4>
                      <div className='row'>
                        <div className='col-md-10 col-xs-9'>
                          <div>Choice Of Peppercorn, Creamy Stilton &amp; Port.</div>
                        </div>
                        <div className='col-md-2 col-xs-3'>
                          <div className='pull-right' style={{ fontSize: '18px' }}>£2.50</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h2'>
                  <h2 >Grill Menu</h2>
                </div>
                <div className='para-h4'>
                  <h4>Whole Rack Of Ribs <p className='pull-right'>£22.95</p></h4>
                  <p>A Whole Rack Of Ribs Smothered In Smokey Bbq Sauce, Served With Chips, Homemade Coleslaw, Corn On The Cob &amp; Beer Battered Onion Rings</p>
                </div>
                <div className='para-h4'>
                  <h4>10oz Grilled Gammon Steak<p className='pull-right'>£16.95</p></h4>
                  <p>Served with either Two Free Range fried Eggs or Two Grilled Pineapple rings, with Chips, Grilled Tomato &amp; Garden Peas</p>
                </div>
                <div className='para-h4'>
                  <h4>The Woolpack Mixed Grill<p className='pull-right'>23.95</p></h4>
                  <p>Ribeye Steak, Farmhouse Sausage, Bbq Pork Strips &amp; Chicken Wings Served With Sautéed Mushrooms, Peas, Tomatoes &amp; Chips</p>
                </div>
              </div>
            </div>
            <Sides />
          </div>
        </Layout>
      </Fragment >
    )
  }
}

export default StartersGrill
